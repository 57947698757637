import React from 'react';
import { Link } from 'react-router-dom';
import registration_steps from "../../assets/images/SAA_registration_steps.png";
import SAA_Logo from "../../assets/images/SAAlogo_transparent.png";
import Leuven_Logo from "../../assets/images/stadleuven_transparent.png";
import mPath_Logo from "../../assets/images/logo_m-Path.png";
import './Register.css';

const Register = () => {
    return (
        <div className="App">
            <div className="App-Pages">
                <div className="Page-Background"></div>
                <div className="titleCont">
                    <div className="title-top-pages">
                        Register for SAA 2025 today!
                    </div>
                </div>
                <div className="registerButton register-page-button">
                    <Link to="https://icts.kuleuven.be/apps/onebutton/registrations/834040">
                        <button>Register Now</button>
                    </Link>
                </div>
                <div className="ContType4 HomeWideCont">
                    <div className="twocols-container twocols-container-inverse">
                        <div className="ContType4 HomeCont">
                            <div className="registration-info">
                                <h2>How to register</h2>
                                <p>To register for the conference, follow the register now button and complete the following steps:</p>
                                <img src={registration_steps} alt="Steps to register" width="100%" />

                                <h2>Registration fees</h2>
                                <p>The registration fees can be found in the table below. SAA members from low/middle-income countries are eligible for a discount. Eligibility will be determined based on the list of low/middle-income countries available <a href="https://www.oecd.org/en/topics/oda-eligibility-and-conditions/dac-list-of-oda-recipients.html">here</a>.</p>
                                <p>Please note that payments can only be made via <b>credit card</b> at the time of registration. <b>Invoice payments are not available.</b></p> 
                                <p>Upon successful registration and payment, an automatic confirmation will be sent to you via email.</p>
                                <table>
                                    <thead>
                                        <tr>
                                            <th> </th><th>Early bird fee</th><th>Normal fee</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Full/Emeritus member</td><td>325 EUR</td><td>375 EUR</td>
                                        </tr>
                                        <tr>
                                            <td>Early Career member</td><td>175 EUR</td><td>225 EUR</td>
                                        </tr>
                                        <tr>
                                            <td>Low Income Country member</td><td>150 EUR</td><td>200 EUR</td>
                                        </tr>
                                        <tr>
                                            <td>Non-member</td><td>450 EUR</td><td>500 EUR</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h2>Social activities and events</h2>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Activity</th><th>Fee</th><th>Date</th><th>Sponsored by</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Stella brewery visit for early career researchers</td>
                                            <td>Free of charge</td>
                                            <td>Sunday 25 May</td>
                                            <td><a href="https://ambulatory-assessment.org/"><img src={SAA_Logo} alt="SAA" style={{height:'2em'}}></img></a></td>
                                        </tr>
                                        <tr>
                                            <td>Welcome reception</td>
                                            <td>Free of charge</td>
                                            <td>Monday 26 May</td>
                                            <td><a href="https://ambulatory-assessment.org/"><img src={SAA_Logo} alt="SAA" style={{height:'2em'}}></img></a> <a href="https://www.leuven.be/en"><img src={Leuven_Logo} alt="City of Leuven" style={{height:'2em'}}></img></a></td>
                                        </tr>
                                        <tr>
                                            <td>Conference dinner + m-Party</td>
                                            <td>65 EUR</td>
                                            <td>Tuesday 27 May</td>
                                            <td><a href="https://m-path.io/"><img src={mPath_Logo} alt="m-path" style={{height:'2em'}}></img></a></td>
                                        </tr>
                                        <tr>
                                            <td>Mentor lunch (Registration ends the 4<sup>th</sup> of April!)</td>
                                            <td>Free of charge</td>
                                            <td>Wednesday 28 May</td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h2>Workshops</h2>
                                <p>The workshops given at SAA 2025 will be announced at a later date. Registration for the workshops will be separate from registration for the conference. There will be a 50€ fee to register for a workshop. More details about how to register for a workshop will be announced soon!</p>
                                <h2>Cancellation policy</h2>
                                <p>If circumstances prevent you from enjoying your planned stay, it is possible to cancel and receive a reimbursement depending on the temporal proximity of the conference.   Please notify us about changes to registrations or cancellations at <a href="mailto:info@saa2025.com">info@saa2025.com</a>.</p>
                                Cancellation policy:
                                <ul>
                                    <li>Prior to 27 March, 2025: full reimbursement (excluding administrative cost of 25 EUR)</li>
                                    <li>From 27 March, 2025 to 26 April, 2025: 50% reimbursement (excluding administrative cost of 25 EUR)</li>
                                    <li>From 27 April 2025 onwards: no reimbursement</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
